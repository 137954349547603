import React, { useState, useContext, useRef } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import { Menu } from "@material-ui/core";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";
import ContactListShareModal from "../ContactListShareModal";
import { AuthContext } from "../../context/Auth/AuthContext";

import EditMessageChat from "../EditMessageChat"; // Certifique-se de importar o componente

const useStyles = makeStyles((theme) => ({
  menuContainer: {
    position: "relative",
  },
}));

const MessageOptionsMenu = ({ message, menuOpen, handleClose, anchorEl }) => {
  const { setReplyingMessage } = useContext(ReplyMessageContext);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isShareContactModalOpen, setIsShareContactModalOpen] = useState(false);
  const [showEditMessageChat, setShowEditMessageChat] = useState(false);
  const [sharedContacts, setSharedContacts] = useState([]);
  const menuContainerRef = useRef(null);

  const { user } = useContext(AuthContext);

  const classes = useStyles();

  const canEditMessage = (message) => {
    if (!message.createdAt) {
      return false; // Se não há data de criação, não pode editar
    }

    // Se for um desses tipos, retorna false.
    switch (message.mediaType) {
      case "image":
      case "contactMessage":
      case "audio":
      case "chat_event":
        return false;
    }

    // Se a mensagem não for de quem enviou e e ele não for admin, não pode editar.
    if (message.userId != user.id && user.profile.toUpperCase() != "ADMIN") {
      return false;
    }

    const minutesAgo = new Date(Date.now() - 15 * 60 * 1000); // 15 minutos atrás
    const messageCreatedAt = new Date(message.createdAt);

    return messageCreatedAt > minutesAgo; // Retorna true se a mensagem foi enviada nos últimos 15 minutos
  };

  const handleEditMessage = async () => {
    if (canEditMessage(message)) {
      try {
        // Aqui, ao invés de enviar uma requisição para editar a mensagem,
        // vamos simplesmente mostrar o componente de edição
        setShowEditMessageChat(true);
        handleClose();
      } catch (err) {
        toastError(err);
      }
    }
  };

  const handleDeleteMessage = async () => {
    try {
      await api.delete(`/messages/${message.id}`);
    } catch (err) {
      toastError(err);
    }
  };

  const hanldeReplyMessage = () => {
    setReplyingMessage(message);
    handleClose();
  };

  const handleOpenConfirmationModal = (e) => {
    setConfirmationOpen(true);
    handleClose();
  };

  const hanldeForwardMessageClick = () => {
    setIsShareContactModalOpen(true);
    handleClose();
  };

  const hanldeForwardMessage = async (contacts) => {
    try {
      // Mapeia cada contato e envia uma solicitação para o backend para encaminhar a mensagem
      await Promise.all(
        contacts.map(async (contact) => {
          const forwardData = {
            contactId: contact.id, // ID do contato para o qual está encaminhando a mensagem
            messageId: message.id,
            number: contact.number, // número do contato para o qual está encaminhando a mensagem
            isGroup: contact.isGroup, // Definir true se estiver encaminhando para um grupo
          };

          await api.post(`/messages/forward/${message.ticketId}`, forwardData);
        })
      );

      // Fecha o modal após o encaminhamento de todas as mensagens
      setIsShareContactModalOpen(false);
      setSharedContacts([]); // limpa a lista de contatos.
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.menuContainer} ref={menuContainerRef}>
      <ConfirmationModal
        title={i18n.t("messageOptionsMenu.confirmationModal.title")}
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={handleDeleteMessage}
      >
        {i18n.t("messageOptionsMenu.confirmationModal.message")}
      </ConfirmationModal>

      {isShareContactModalOpen && (
        <ContactListShareModal
          onSelectContacts={hanldeForwardMessage}
          onClose={() => setIsShareContactModalOpen(false)}
        />
      )}

      {showEditMessageChat && (
        <EditMessageChat
          messageId={message.id}
          messageBody={message.body}
          onSendMessage={(editedMessage) => {
            setShowEditMessageChat(false); // Fecha o componente após enviar a mensagem editada
          }}
          onClose={() => {
            setShowEditMessageChat(false); // Fecha o componente após emitir o evento
          }}
          parentSize={menuContainerRef.current?.getBoundingClientRect()}
        />
      )}

      {!isShareContactModalOpen && (
        <Menu
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={menuOpen}
          onClose={handleClose}
        >
          <MenuItem onClick={hanldeForwardMessageClick}>
            {"Encaminhar"}
          </MenuItem>
          {message.fromMe && (
            <MenuItem onClick={handleOpenConfirmationModal}>
              {i18n.t("messageOptionsMenu.delete")}
            </MenuItem>
          )}
          {message.fromMe && canEditMessage(message) && (
            <MenuItem onClick={handleEditMessage}>{"Editar"}</MenuItem>
          )}

          <MenuItem onClick={hanldeReplyMessage}>
            {i18n.t("messageOptionsMenu.reply")}
          </MenuItem>
        </Menu>
      )}
    </div>
  );
};

export default MessageOptionsMenu;
