import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import api from "../../services/api"; 

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  buttonProgress: {
    position: "absolute",
    marginLeft: theme.spacing(1),
  },
}));

const FlowSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Muito curto!")
    .max(50, "Muito longo!")
    .required("Campo obrigatório"),
  description: Yup.string()
    .max(200, "Muito longo!"),
});

const FlowModal = ({ open, onClose, flowId, onSave }) => {
  const classes = useStyles();
  const initialState = { name: "", description: "" };
  const [flow, setFlow] = useState(initialState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchFlow = async () => {
      if (flowId) {
        setLoading(true);
        try {
          const { data } = await api.get(`/flows/${flowId}`);
          setFlow(data);
        } catch (error) {
          toast.error("Erro ao carregar os dados do fluxo.");
        } finally {
          setLoading(false);
        }
      } else {
        setFlow(initialState);
      }
    };

    fetchFlow();
  }, [flowId, open]);

  const handleClose = () => {
    onClose();
    setFlow(initialState);
  };

  const handleSave = async (values) => {
    try {
      let response;
      if (flowId) {
        // Se flowId estiver presente, atualiza o fluxo
        response = await api.put(`/flows/${flowId}`, values);
        toast.success("Fluxo atualizado com sucesso!");
      } else {
        // Caso contrário, cria um novo fluxo
        response = await api.post("/flows", values);
        toast.success("Fluxo adicionado com sucesso!");
      }
      onSave(response.data); // Passa os dados retornados do backend
    } catch (error) {
      toast.error("Erro ao salvar os dados do fluxo.");
    }
  };
  

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>{flowId ? "Editar Fluxo" : "Adicionar Fluxo"}</DialogTitle>
      {loading ? (
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      ) : (
        <Formik
          initialValues={flow}
          enableReinitialize
          validationSchema={FlowSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleSave(values); // Chama a função de salvar
            setSubmitting(false);
            handleClose();
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <Field
                  as={TextField}
                  label="Nome"
                  name="name"
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  required
                />
                <Field
                  as={TextField}
                  label="Descrição"
                  name="description"
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  multiline
                  rows={4}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="secondary" variant="outlined">
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                >
                  {flowId ? "Salvar" : "Adicionar"}
                  {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
};

export default FlowModal;
