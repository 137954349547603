import React, { useRef, useState } from "react";
import {
  Modal,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PersonIcon from "@material-ui/icons/Person";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";

import GalleryImageModal from "../GalleryImageModal";

const useStyles = makeStyles({
  container: {
    position: "absolute",
    left: "55vw",
    bottom: "-20px",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: "10px",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  },
  input: {
    display: "none",
  },
  list: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
  },
  listIcon: {
    minWidth: "0",
  },
  listItem: {
    display: "flex",
    columnGap: "10px",
    padding: "0",
    margin: "0",
  },
  primaryText: {
    fontSize: "16px",
    color: "#3B4A54",
  },
});

const AttachmentModal = ({
  open,
  onClose,
  onUpload,
  onLocationSelect,
  onOpenShareContactModal,
  ticketId
  
}) => {
  const classes = useStyles();
  const fileInputRef = useRef(null);
  const [isPhotoVideoModalOpen, setIsPhotoVideoModalOpen] = useState(false);

  const handleFileInputChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    onUpload(selectedFiles);
  };


  const handleLocationSelect = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          onLocationSelect({ latitude, longitude });
        },
        (error) => {
          console.error("Erro ao obter a localização:", error);
        }
      );
    } else {
      console.error("Geolocalização não suportada pelo navegador.");
    }
  };

  const handleOpenShareContactModal = () => {
    if (onOpenShareContactModal) {
      onOpenShareContactModal();
      onClose();
    }
  };

  const handleClosePhotoVideoModal = () => {
    setIsPhotoVideoModalOpen(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
      >
        <div className={classes.container}>         
          <List className={classes.list}>
            <ListItem
              button
              onClick={() => fileInputRef.current.click()}
              className={classes.listItem}
            >
              <ListItemIcon className={classes.listIcon}>
                <DescriptionIcon style={{ color: "#7F66FF" }} />
              </ListItemIcon>
              <ListItemText
                primary="Documento"
                primaryTypographyProps={{ className: classes.primaryText }}
              />
            </ListItem>
            <input
              multiple
              type="file"
              ref={fileInputRef}
              className={classes.input}
              onChange={handleFileInputChange}
            />
            {/* Esse list item deve ter um input para ele tambem, o de fotos*/}
            <ListItem
              button
              onClick={() => setIsPhotoVideoModalOpen(true)}
              className={classes.listItem}
            >
              <ListItemIcon className={classes.listIcon}>
                <PhotoLibraryIcon style={{ color: "#007BFC" }} />
              </ListItemIcon>
              <ListItemText
                primary="Fotos e vídeos"
                primaryTypographyProps={{ className: classes.primaryText }}
                
              />
            </ListItem>
            <ListItem
              button
              onClick={handleLocationSelect}
              className={classes.listItem}
            >
              <ListItemIcon className={classes.listIcon}>
                <LocationOnIcon style={{color: "#11AB9E"}}/>
              </ListItemIcon>
              <ListItemText
                primary="Localização"
                primaryTypographyProps={{ className: classes.primaryText }}
              />
            </ListItem>
            <ListItem
              button
              onClick={handleOpenShareContactModal}
              className={classes.listItem}
            >
              <ListItemIcon className={classes.listIcon}>
                <PersonIcon style={{ color: "#009DE2" }} />
              </ListItemIcon>
              <ListItemText
                primary="Contato"
                primaryTypographyProps={{ className: classes.primaryText }}
              />
            </ListItem>
          </List>
        </div>
      </Modal>

      {isPhotoVideoModalOpen && 
        <GalleryImageModal 
          onModalClose={handleClosePhotoVideoModal}
          ticketId={ticketId}
      />}
      
    </>
  );
};

export default AttachmentModal;
