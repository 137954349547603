import React, { useState, useEffect } from "react";
import api from "../../services/api";
import ApexChart from "../../components/ApexChart";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({

    mainContainer: {
      background: "#FAFAFA",
      padding: "1rem"
    },

    chartCards: {
        display: "flex",
        columnGap: "10px"
    },

    chartCard: {
      background: "#FFFFFF",
      borderRadius: "6px"
    }

    
  }));

const AdminDashboard = () => {
    const classes = useStyles();

  const [socketStatus, setSocketStatus] = useState({
    connectedClients: 0,
    activeRooms: [],
  });

  useEffect(() => {
    // Função para buscar o status do Socket.IO do backend
    const fetchSocketStatus = async () => {
      try {
        const response = await api.get("/status"); // Endpoint que retorna o status do Socket.IO
        const { connectedClients, rooms } = response.data;
        console.log(response.data);
        setSocketStatus({
          connectedClients,
          activeRooms: rooms,
        });
      } catch (error) {
        console.error("Erro ao buscar status do Socket.IO:", error);
      }
    };

    // Chama a função inicialmente ao carregar o componente
    fetchSocketStatus();

    // Define um intervalo para atualizar o status a cada 5 segundos (5000ms)
    const interval = setInterval(fetchSocketStatus, 5000);

    // Limpa o intervalo quando o componente é desmontado para evitar vazamentos de memória
    return () => clearInterval(interval);
  }, []); // O array vazio [] garante que o useEffect será executado apenas uma vez (no montagem do componente)

  // Configurações do gráfico radial (Radial Bar Chart) para clientes conectados
  const clientsRadialOptions = {
    chart: {
      id: "clients-radial-chart",
      type: "radialBar",
      height: 350,
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "16px",
          },
          total: {
            show: true,
            label: "Clientes Conectados",
          },
        },
      },
    },
    labels: ["Conectados"],
    colors: ['#00C14D'],
  };
  const clientsRadialSeries = [socketStatus.connectedClients];

  // Configurações do gráfico radial (Radial Bar Chart) para salas ativas
  const roomsRadialOptions = {
    chart: {
      id: "rooms-radial-chart",
      type: "radialBar",
      height: 350,
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "16px",
          },
          total: {
            show: true,
            label: "Salas Ativas",
          },
        },
      },
    },
    labels: ["Ativas"],
    colors: ['#00C14D'],
  };
  const roomsRadialSeries = [socketStatus.activeRooms.length];

  return (
    <div className="mainContainer">
      <h1>Status do Socket.IO</h1>
      <div className="radial-charts-container">
        <div className={classes.chartCards}>
          <div className={classes.chartCard}>
            <ApexChart
              type="radialBar"
              series={clientsRadialSeries}
              options={clientsRadialOptions}
            />
          </div>
          <div className={classes.chartCard}>
            <ApexChart
              type="radialBar"
              series={roomsRadialSeries}
              options={roomsRadialOptions}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
