import React, { useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // estilo padrão
import "react-date-range/dist/theme/default.css"; // tema padrão
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR"; // Importa o idioma Português Brasil

const DateRangePicker = ({ startDate: startDateProp, endDate: endDateProp, setStartDate, setEndDate }) => {
  const [isOpen, setIsOpen] = useState(false); // Estado para controlar a abertura do DateRange picker
  const [startDate, setLocalStartDate] = useState(startDateProp || new Date());
  const [endDate, setLocalEndDate] = useState(endDateProp || new Date());

  const handleSelect = (ranges) => {
    setLocalStartDate(ranges.selection.startDate);
    setLocalEndDate(ranges.selection.endDate);
  };

  const handleConfirm = () => {
    setStartDate(startDate);
    setEndDate(endDate);
    setIsOpen(false);
  };

  const handleCancel = () => {
    setLocalStartDate(startDateProp || new Date());
    setLocalEndDate(endDateProp || new Date());
    setIsOpen(false);
  };

  const selectionRange = {
    startDate: startDate || new Date(),
    endDate: endDate || new Date(),
    key: "selection",
  };

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      {isOpen && (
        <div style={{ position: "absolute", zIndex: "999", background: "#fff", padding: "10px", border: "1px solid #ccc", borderRadius: "4px", boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)" }}>
          <DateRange
            locale={ptBR}
            ranges={[selectionRange]}
            onChange={handleSelect}
            showDateDisplay={false}
          />
          <div style={{ marginTop: "10px", textAlign: "center" }}>
            <button
              style={{
                backgroundColor: "#02322E",
                color: "#fff",
                padding: "8px 16px",
                margin: "0 4px",
                borderRadius: "4px",
                border: "none",
                cursor: "pointer",
              }}
              onClick={handleCancel}
            >
              Cancelar
            </button>
            <button
              style={{
                backgroundColor: "#fff",
                color: "#02322E",
                padding: "8px 16px",
                margin: "0 4px",
                borderRadius: "4px",
                border: "1px solid #02322E",
                cursor: "pointer",
              }}
              onClick={handleConfirm}
            >
              Confirmar
            </button>
          </div>
        </div>
      )}
      <button
        style={{
          backgroundColor: "#02322E",
          color: "#fff",
          padding: "12px 18px",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
          fontStyle: "bold"
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        FILTRAR
      </button>
    </div>
  );
};

export default DateRangePicker;
