import React, { useEffect, useState, useCallback, useRef } from "react";
import ReactFlow, {
  MiniMap,
  Controls,
  useNodesState,
  useEdgesState,
  addEdge,
} from "react-flow-renderer";
import {
  Modal,
  Typography,
  Button,
  withStyles,
  Dialog,
} from "@material-ui/core";
import { toast } from "react-toastify";
import QueueSelect from "../FlowQueueSelect";
import CustomNode from "../CustomNode";
import api from "../../services/api";

const initialNodes = [
  {
    id: "1",
    type: "input",
    data: { label: "Inicio" },
    position: { x: 0, y: 0 },
    sourcePosition: "right",
  },
];

const nodeTypes = {
  customNode: CustomNode,
};

const initialEdges = [];

const styles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#fff",
    boxShadow: "0 3px 5px rgba(0, 0, 0, 0.3)",
    padding: 20,
    width: "90%",
    height: "90%",
  },
};

function ReactFlowBuilder({ classes, open, onClose, flowId }) {
  const edgeReconnectSuccessful = useRef(true);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [modalNode, setModalNode] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedQueues, setSelectedQueues] = useState([]);
  const [flow, setFlow] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) =>
        node.id === "1"
          ? { ...node, data: { ...node.data, flowId: flowId } }
          : node
      )
    );

    const fetchFlow = async () => {
      if (flowId) {
        setLoading(true);
        try {
          const { data } = await api.get(`/flows/${flowId}`);
          setFlow(data);
        } catch (error) {
          toast.error("Erro ao carregar os dados do fluxo.");
        } finally {
          setLoading(false);
        }
      } else {
        setFlow([]);
      }
    };

    fetchFlow();
  }, [flowId]);

  const handleConnect = (connection) => {
    setEdges((eds) => addEdge(connection, eds));
  };

  const addNode = () => {
    const newNodeId = `menu-${nodes.length}`;
    const newNode = {
      id: newNodeId,
      position: { x: Math.random() * 400, y: Math.random() * 400 },
      data: { label: "Menu", type: "Menu", queues: [] },
      type: "customNode",
    };
    setNodes((nds) => [...nds, newNode]);
  };

  const openModal = (nodeData) => {
    setModalNode(nodeData);
    setSelectedQueues(nodeData.queues || []);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const saveChanges = () => {
    if (modalNode) {
      const updatedNode = {
        ...modalNode,
        data: {
          ...modalNode.data,
          queues: selectedQueues.map((queue) => ({
            id: queue.id,
            label: queue.name,
          })),
        },
      };
      setNodes((nds) =>
        nds.map((node) => (node.id === modalNode.id ? updatedNode : node))
      );
    }
    closeModal();
  };

  const saveFlow = useCallback(() => {
    const flowData = {
      nodes,
      edges,
    };
    const json = JSON.stringify(flowData);
    console.log(json);
    handleSave(json);
  }, [nodes, edges]);

  const handleSave = async (data) => {
    try {
      await api.put(`/flows/${flowId}`, JSON.parse(data));
    } catch (error) {}
  };

  const onNodeClick = (event, node) => {
    console.log(node);
    if (node.data.type === "Menu") {
      openModal(node);
    }
  };

  const onEdgeClick = (event, edge) => {
    setEdges((eds) => eds.filter((e) => e.id !== edge.id));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      PaperProps={{
        style: { maxWidth: "100%" },
      }}
    >
      <div className={classes.paper} style={{ position: "relative" }}>
        <Typography variant="h6" gutterBottom>
          React Flow Builder
        </Typography>
        <Button
          onClick={saveFlow}
          variant="contained"
          color="primary"
          style={{ position: "absolute", right: "10px" }}
        >
          Salvar Fluxo
        </Button>
        <Button
          onClick={addNode}
          variant="contained"
          color="primary"
          style={{ marginBottom: 10 }}
        >
          Adicionar Nó do Tipo Menu
        </Button>
        <div style={{ width: "100%", height: "95vh" }}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onEdgeClick={onEdgeClick}
            onConnect={handleConnect}
            nodeTypes={nodeTypes}
            onNodeClick={onNodeClick}
          >
            <MiniMap />
            <Controls />
          </ReactFlow>
        </div>

        {/* Modal para exibir informações do nó */}
        <Dialog open={modalOpen} onClose={closeModal}>
          <div style={{ padding: "20px" }}>
            <Typography variant="h6">
              Menu: {modalNode ? modalNode.label : ""}
            </Typography>
            <QueueSelect
              selectedQueues={selectedQueues} 
              onChange={setSelectedQueues}
              title={"Filas"}
            />
            <div style={{ marginTop: "20px" }}>
              <Button
                onClick={saveChanges}
                variant="contained"
                color="primary"
                style={{ marginRight: 10 }}
              >
                Salvar
              </Button>
              <Button
                onClick={closeModal}
                variant="contained"
                color="secondary"
              >
                Fechar
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    </Dialog>
  );
}

export default withStyles(styles)(ReactFlowBuilder);
