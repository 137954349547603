import React from "react";
import Typography from "@material-ui/core/Typography";

export default function Title({ color, children }) {
    return (
        <Typography style={{ color: color || undefined }} variant="h5" color={color ? undefined : "primary"} gutterBottom>
            {children}
        </Typography>
    );
}

