import React, { useState, createContext } from "react";

const ReplyMessageContext = createContext();
const EditedMessageContext = createContext();

const ReplyMessageProvider = ({ children }) => {
    const [replyingMessage, setReplyingMessage] = useState(null);

    return (
        <ReplyMessageContext.Provider value={{ replyingMessage, setReplyingMessage }}>
            <EditedMessageProvider>
                {children}
            </EditedMessageProvider>
        </ReplyMessageContext.Provider>
    );
};

const EditedMessageProvider = ({ children }) => {
    const [editedMessage, setEditedMessage] = useState(null);

    return (
        <EditedMessageContext.Provider value={{ editedMessage, setEditedMessage }}>
            {children}
        </EditedMessageContext.Provider>
    );
};

export { ReplyMessageContext, EditedMessageContext, ReplyMessageProvider };

