import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import moment from "moment"; // Importando moment para manipulação de datas

import api from "../../services/api";
import toastError from "../../errors/toastError";
import TableWithPagination from "../../components/TableWithPagination";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import DateRangePicker from "../../components/DateRangePicker";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
  },

  truncatedText: {
    display: "inline-block",
    maxWidth: "200px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    cursor: "pointer",
  },
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    padding: theme.spacing(1),
  },
}));

const TicketsTag = () => {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tag, setTag] = useState({ name: "", color: "" }); // Estado para armazenar os dados da etiqueta
  const [ticketsData, setTicketsData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTickets, setFilteredTickets] = useState([]);

  useEffect(() => {
    const fetchTicketsAndTag = async () => {
      setLoading(true);
      try {
        // Primeira req para obter os tickets
        const { data: ticketsData } = await api.get(`/tags/${id}/tickets`);
        const formattedTickets = ticketsData.map((ticket) => ({
          ...ticket,
          updatedAt: moment(ticket.updatedAt).format("DD/MM/YYYY HH:mm"),
          status: translateStatus(ticket.status),
        }));

        // Segunda req para obter os dados da etiqueta
        const { data: tagData } = await api.get(`/ticket-tags/tag/${id}`);
        const { name, color } = tagData;

        // Atualizando o estado com os tickets e os dados da etiqueta
        setTickets(formattedTickets);
        setTicketsData(formattedTickets);
        setTag({ name, color });

        console.log(formattedTickets);
      } catch (err) {
        toastError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchTicketsAndTag();
  }, [id]);

  useEffect(() => {
    filterTickets();
  }, [searchQuery, startDate, endDate, ticketsData]);

  const filterTickets = () => {
    let filtered = ticketsData;

    if (searchQuery.trim()) {
      const searchTerm = searchQuery.toLowerCase().trim();
      filtered = filtered.filter((ticket) => {
        if (ticket.id.toString().includes(searchTerm)) {
          return true;
        }
        if (
          ticket.contactName &&
          ticket.contactName.toLowerCase().includes(searchTerm)
        ) {
          return true;
        }

        return false;
      });
    }

    if (startDate) {
      const startDateFull = moment(startDate, "YYYY-MM-DD");

      filtered = filtered.filter((ticket) => {
        const ticketDate = moment(ticket.updatedAt, "DD/MM/YYYY HH:mm");
        return ticketDate.isSameOrAfter(startDateFull, "day");
      });
    }

    if (endDate) {
      const endDateFull = moment(endDate, "YYYY-MM-DD");

      filtered = filtered.filter((ticket) => {
        const ticketDate = moment(ticket.updatedAt, "DD/MM/YYYY HH:mm");
        return ticketDate.isSameOrBefore(endDateFull, "day");
      });
    }

    setFilteredTickets(filtered);
  };

  const translateStatus = (status) => {
    switch (status) {
      case "open":
        return "Aberto";
      case "closed":
        return "Fechado";
      case "pending":
        return "Pendente";
      default:
        return status;
    }
  };

  const columns = [
    { field: "id", headerName: "ID", minWidth: 50 },
    { field: "status", headerName: "Status", minWidth: 100, sortable: true },
    {
      field: "contactName",
      headerName: "Contato",
      minWidth: 150,
      render: (value) => value || "N/A",
    },
    {
      field: "userName",
      headerName: "Atribuído",
      minWidth: 150,
      render: (value) => value || "N/A",
    },

    {
      field: "lastMessage",
      headerName: "Ulti. Mensagem",
      minWidth: 200,
      render: (value) => (
        <Tooltip title={value} classes={{ tooltip: classes.tooltip }}>
          <span className={classes.truncatedText}>{value}</span>
        </Tooltip>
      ),
    },
    { field: "updatedAt", headerName: "Ulti. Atualização", minWidth: 150 },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRowClick = (row) => {
    history.push(`/tickets/${row.id}`);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={classes.root}>
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}
      >
        <Title color={tag.color}>{tag.name}</Title>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "1rem 0",
        }}
      >
        <MainHeaderButtonsWrapper>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              columnGap: "10px",
            }}
          >
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
            <TextField
              className={classes.searchBar}
              label="Pesquisar"
              placeholder="ID, Contato"
              variant="outlined"
              type="search"
              size="small"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "gray" }} />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </MainHeaderButtonsWrapper>
      </div>
      <TableWithPagination
        columns={columns}
        data={filteredTickets}
        rowsPerPageOptions={[10, 25, 50]}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        count={filteredTickets.length}
        onRowClick={handleRowClick}
        customizeColumns={true}
      />
    </div>
  );
};

export default TicketsTag;
