import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import api from "../../services/api";
import OperatorTable from "../../components/TableWithPagination";
import { useHistory } from "react-router-dom";
import DateRangePicker from "../../components/DateRangePicker";
import moment from "moment";
import ApexChart from "../../components/ApexChart";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    display: "flex",
  },
  card: {
    padding: "20px",
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  searchBar: {
    marginBottom: theme.spacing(2),
  },
  tableContainer: {
    backgroundColor: "#fff",
    padding: theme.spacing(2),
    position: "relative",
  },
  header: {
    marginBottom: theme.spacing(2),
  },
}));

const useStatusStyles = makeStyles({
  online: {
    color: "green",
    fontWeight: "600",
    fontSize: "0.8rem",
  },
  offline: {
    color: "red",
    fontWeight: "600",
    fontSize: "0.8rem",
  },
});

const Status = ({ isOnline }) => {
  const classes = useStatusStyles();
  return (
    <span className={isOnline ? classes.online : classes.offline}>
      {isOnline ? "Online" : "Offline"}
    </span>
  );
};

const AppReports = () => {
  const classes = useStyles();
  const history = useHistory();

  const [cardsData, setCardsData] = useState([]);
  const [agentsData, setAgentsData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showChart, setShowChart] = useState(false);

  const fetchCardsData = async () => {
    try {
      const response = await api.get("/reports/cards");
      setCardsData(response.data);
    } catch (error) {
      console.error("Erro ao processar o pedido:", error);
    }
  };

  const fetchAgentsData = async (startDate, endDate) => {
    try {
      const params = {};
      if (startDate) params.startDate = startDate;
      if (endDate) params.endDate = endDate;

      if (startDate && endDate) {
        const newEndDate = new Date(endDate);
        newEndDate.setDate(newEndDate.getDate() + 1);
        params.endDate = newEndDate.toISOString(); // Formato ISO 8601
      }
      const response = await api.get("/reports/agents-list", { params });
      setAgentsData(response.data);
      setFilteredData(response.data);
    } catch (error) {
      console.error("Erro ao processar o pedido:", error);
    }
  };

  useEffect(() => {
    fetchCardsData();
    fetchAgentsData(); // Carrega os dados inicialmente sem filtros
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      fetchAgentsData(
        startDate ? moment(startDate).format("YYYY-MM-DD") : "",
        endDate ? moment(endDate).format("YYYY-MM-DD") : ""
      );
      setShowChart(true);
    } else {
      setShowChart(false);
      setFilteredData(agentsData); // Exibe todos os dados inicialmente
    }
  }, [startDate, endDate]);

  useEffect(() => {
    const filterAndSortData = () => {
      let filtered = agentsData;
      if (searchQuery.length >= 3) {
        const searchTerms = searchQuery.split(",").map(term => term.trim().toLowerCase());
        filtered = agentsData.filter((agent) =>
          searchTerms.some((term) =>
            Object.values(agent).some((value) =>
              value.toString().toLowerCase().includes(term)
            )
          )
        );
      }
      filtered = filtered.sort((a, b) => a.nome.localeCompare(b.nome));
      setFilteredData(filtered);
    };

    filterAndSortData();
  }, [searchQuery, agentsData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRowClick = (row) => {
    history.push(`/user-tickets-list/${row.id}`);
  };

  const columns = [
    { field: "nome", headerName: "Nome", minWidth: 150 },
    {
      field: "isOnline",
      headerName: "Status",
      minWidth: 100,
      render: (value) => <Status isOnline={value} />,
    },
    { field: "ticketsAbertos", headerName: "Atd.Acontecendo", minWidth: 150 },
    { field: "ticketsFechados", headerName: "Atd.Realizados", minWidth: 150 },
  ];

  const seriesData = [
    {
      name: "Acontecendo",
      data: filteredData.map((agent) => agent.ticketsAbertos),
    },
    {
      name: "Realizados",
      data: filteredData.map((agent) => agent.ticketsFechados),
    },
  ];

  const chartOptions = {
    chart: {
      height: 200,
      type: "bar",
    },
    plotOptions: {
      bar: {
        borderRadius: 6,
        dataLabels: {
          position: "top",
        },
        columnWidth: "20px",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: "10px",
        colors: ["#304758"],
      },
    },
    colors: ["#1E90FF", "#FF6347"], // cores das barras
    xaxis: {
      categories: filteredData.map((agent) => agent.nome),
      position: "top",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val;
        },
      },
    },
    title: {
      text: "Chamadas por Operador",
      floating: true,
      offsetY: 330,
      align: "center",
      style: {
        color: "#444",
      },
    },
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {!showChart && (
          <>
            <Grid item xs={12} sm={8}>
              <Paper className={`${classes.card} ${classes.leftCard}`}>
                <Typography variant="h5" component="h2">
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Box>
                      <h5>Conversas</h5>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Typography variant="body1">Acontecendo</Typography>
                        <Typography variant="body2">
                          {cardsData.abertas}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Typography variant="body1">Sem Atendente</Typography>
                        <Typography variant="body2">
                          {cardsData.naoAtendidas}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Typography variant="body1">Sem Fila</Typography>
                        <Typography variant="body2">
                          {cardsData.naoAtribuida}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Typography variant="body1">Pendentes</Typography>
                        <Typography variant="body2">
                          {cardsData.pendente}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Paper className={`${classes.card} ${classes.rightCard}`}>
                <Typography variant="h5" component="h2">
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Box>
                      <h5>Usuários</h5>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Typography variant="body1">Online</Typography>
                        <Typography variant="body2">
                          {cardsData.onlines}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Typography variant="body1">Offline</Typography>
                        <Typography variant="body2">
                          {cardsData.offlines}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Typography>
              </Paper>
            </Grid>
          </>
        )}

        {showChart && (
          <Grid item xs={12}>
            <Paper
              className={`${classes.card}`}
              style={{ background: "white", borderRadius: "6px" }}
            >
              <ApexChart
                type="bar"
                series={seriesData}
                options={chartOptions}
                startDate={startDate}
                endDate={endDate}
              />
              <span>Período: {startDate ? moment(startDate).format("DD/MM/YYYY") : 'Não selecionado'} até {endDate ? moment(endDate).format("DD/MM/YYYY") : 'Não selecionado'}</span>
            </Paper>
          </Grid>
        )}

        <Grid item xs={12}>
          <Paper className={classes.tableContainer}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className={classes.header}
            >
              <Grid item>
                <Typography variant="h6">Usuários</Typography>
              </Grid>
              <div
                style={{
                  display: "flex",
                  columnGap: "1.8rem",
                  alignItems: "flex-start",
                }}
              >
                <Grid item>
                  <DateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    className={classes.searchBar}
                    label="Pesquisar"
                    variant="outlined"
                    size="small"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </Grid>
              </div>
            </Grid>
            <OperatorTable
              columns={columns}
              data={filteredData}
              rowsPerPageOptions={[10, 25, 50]}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              count={filteredData.length}
              onRowClick={handleRowClick}
              customizeColumns={true}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default AppReports;
