import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardMedia,
  Button,
  Input,
  TextField,
  IconButton,
  Modal,
  Fade,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import SendIcon from "@material-ui/icons/Send";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
  previewImage: {
    width: "auto",
    height: 300,
    objectFit: "contain",
  },
  previewVideo: {
    width: "auto",
    height: 300,
    objectFit: "contain",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: "#E9EDEF",
    borderRadius: "8px",
    padding: theme.spacing(3),
    outline: "none",
    position: "relative",
    right: "0",
    width: "60%",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  textField: {
    margin: theme.spacing(2, 0),
    width: "100%",
    borderRadius: "8px",
    background: "white",
    position: "relative",
  },
  emojiIcon: {
    position: "absolute",
    right: 10,
    top: "50%",
    transform: "translateY(-50%)",
  },
  card: {
    position: "relative",
    width: "60px",
    height: "60px",
    border: "none",
    cursor: "pointer",
    "&:hover $removeIcon": {
      opacity: 1,
    },
  },
  selectedCard: {
    border: `2px solid #00A884`,
  },
  media: {
    height: 80,
    width: 80,
    objectFit: "cover",
  },
  removeIcon: {
    position: "absolute",
    top: "1px",
    right: "1px",
    color: "grey",
    opacity: 0,
    padding: "0",
    transition: "opacity 0.3s ease-in-out",
  },
  addCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `0.5px solid ${theme.palette.text.primary}`,
    cursor: "pointer",
    height: 60,
    width: 60,
    borderRadius: "6px",
  },
  sendButton: {
    borderRadius: "50%",
    background: "rgb(0,168,132)",
    color: "white",
    padding: "15px",
    width: "60px",
    height: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  previewError: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "#F0F2F5",
    width: "60%",
  },
  emojiPicker: {
    position: "absolute",
    bottom: "80px",
    right: "10px",
    zIndex: 1000,
  },
  closeButton: {
    position: "absolute",
    top: 10,
    left: 10,
    color: "grey",
    padding: 5,
  },
}));

const ImageUploader = ({ onModalClose, ticketId }) => {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileCaptions, setFileCaptions] = useState({}); // Armazena legendas para arquivos
  const [openModal, setOpenModal] = useState(false);
  const [caption, setCaption] = useState("");
  const [previewError, setPreviewError] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  useEffect(() => {
    document.getElementById("file-input").click();
  }, []);

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files).filter((file) =>
      ["image/jpeg", "image/png", "video/mp4", "video/webm"].includes(file.type)
    );
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    if (!selectedFile) {
      const firstFile = newFiles[0];
      setSelectedFile(firstFile);
      setCaption(fileCaptions[firstFile.name] || ""); // Define a legenda inicial
      setOpenModal(true);
    }
  };

  const handleFileRemove = (fileToRemove) => {
    setFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter((file) => file !== fileToRemove);
      if (fileToRemove === selectedFile) {
        const nextFile = updatedFiles[0] || null;
        setSelectedFile(nextFile);
        setCaption(nextFile ? fileCaptions[nextFile.name] || "" : "");
        if (updatedFiles.length === 0) {
          setOpenModal(false);
          onModalClose();
        }
      }
      return updatedFiles;
    });
  };

  const handleSend = async () => {
    // Itera sobre cada arquivo e envia um pedido para cada separada
    for (const file of files) {
      const formData = new FormData();
      formData.append("fromMe", true);
      formData.append("medias", file); // Adiciona o arquivo ao FormData
      formData.append("body", fileCaptions[file.name] || ""); // Adiciona a legenda associada ao arquivo
      formData.append("ticketId", ticketId);
          
      try {
        await api.post(`/messages/${ticketId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log(`File ${file.name} sent successfully.`);
      } catch (err) {
        console.error(`Error sending file ${file.name}:`, err);
      }
    }
    
    // Limpa os estados apos o envio de todos os arquivos
    setOpenModal(false);
    setFiles([]);
    setSelectedFile(null);
    setFileCaptions({});
  };
  
  const handleCardClick = (file) => {
    setSelectedFile(file);
    setCaption(fileCaptions[file.name] || "");
    setPreviewError(false);
  };

  const handleImageLoadError = () => {
    setPreviewError(true);
  };

  const handleEmojiSelect = (emoji) => {
    setCaption((prevCaption) => prevCaption + emoji.native);
    setShowEmojiPicker(false);
  };

  const handleCaptionChange = (event) => {
    const newCaption = event.target.value;
    setCaption(newCaption);
    if (selectedFile) {
      setFileCaptions((prevCaptions) => ({
        ...prevCaptions,
        [selectedFile.name]: newCaption,
      }));
    }
  };

  return (
    <div>
      <input
        accept="image/*,video/*" // Limita os tipos de arquivo aceitos
        className={classes.input}
        id="file-input"
        multiple
        type="file"
        onChange={handleFileChange}
      />

      {openModal && (
        <Modal
          open={openModal}
          onClose={() => {
            if (files.length === 0) {
              setOpenModal(false);
            }
            onModalClose();
          }}
          className={classes.modal}
          closeAfterTransition
        >
          <Fade in={openModal}>
            <div className={classes.modalContent}>
              <IconButton
                className={classes.closeButton}
                onClick={() => {
                  setOpenModal(false);
                  onModalClose();
                }}
              >
                <CloseIcon />
              </IconButton>
              {selectedFile && !previewError ? (
                selectedFile.type.startsWith("image/") ? (
                  <div style={{ background: "white", marginBottom: 20 }}>
                    <img
                      src={URL.createObjectURL(selectedFile)}
                      alt={selectedFile.name}
                      className={classes.previewImage}
                      onError={handleImageLoadError}
                    />
                  </div>
                ) : selectedFile.type.startsWith("video/") ? (
                  <div style={{ background: "white", marginBottom: 20 }}>
                    <video
                      controls
                      src={URL.createObjectURL(selectedFile)}
                      className={classes.previewVideo}
                    />
                  </div>
                ) : null
              ) : previewError ? (
                <div className={classes.previewError}>
                  <InsertDriveFileIcon
                    style={{ fontSize: 80, color: "white" }}
                  />
                  <Typography variant="body1" color="textSecondary">
                    Pré-visualização indisponível
                  </Typography>
                </div>
              ) : null}
              <div>
                {selectedFile
                  ? selectedFile.name
                  : "Nenhum arquivo selecionado"}
              </div>
              <div style={{ position: "relative", width: "100%" }}>
                <TextField
                  className={classes.textField}
                  label="Adicione uma legenda"
                  variant="outlined"
                  value={caption}
                  onChange={handleCaptionChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowEmojiPicker((prev) => !prev)}
                          className={classes.emojiIcon}
                        >
                          <InsertEmoticonIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {showEmojiPicker && (
                  <div className={classes.emojiPicker}>
                    <Picker
                      onSelect={handleEmojiSelect}
                      locale="pt"
                      perLine={8}
                      showPreview={false}
                      showSkinTones={false}
                      i18n={{
                        search: "Procurar",
                        categories: {
                          search: "Resultados da pesquisa",
                          recent: "Recentes",
                          people: "Pessoas",
                          nature: "Natureza",
                          foods: "Comida",
                          activity: "Atividades",
                          places: "Lugares",
                          objects: "Objetos",
                          symbols: "Símbolos",
                          flags: "Bandeiras",
                        },
                      }}
                    />
                  </div>
                )}
              </div>
              <Grid
                container
                spacing={2}
                style={{
                  marginTop: 20,
                  position: "relative",
                  padding: "0 10px",
                  alignItems: "center",
                }}
              >
                {files.map((file, index) => (
                  <Grid item key={index}>
                    <Card
                      className={`${classes.card} ${
                        selectedFile === file ? classes.selectedCard : ""
                      }`}
                      onClick={() => handleCardClick(file)}
                    >
                      <CardMedia
                        className={classes.media}
                        image={URL.createObjectURL(file)}
                        title={file.name}
                      />
                      <IconButton
                        className={classes.removeIcon}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleFileRemove(file);
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Card>
                  </Grid>
                ))}
                <Grid item>
                  <Card
                    className={classes.addCard}
                    onClick={() =>
                      document.getElementById("file-input").click()
                    }
                  >
                    <AddIcon />
                  </Card>
                </Grid>
                <div
                  style={{
                    position: "absolute",
                    right: "10px",
                    bottom: "10px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSend}
                    className={classes.sendButton}
                  >
                    <SendIcon />
                  </Button>
                </div>
              </Grid>
            </div>
          </Fade>
        </Modal>
      )}
    </div>
  );
};

export default ImageUploader;
