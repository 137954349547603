import React from "react";
import ReactApexChart from "react-apexcharts";

const ApexChart = ({ type, series, options }) => {
  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type={type}
        height={options.chart.height}
      />
    </div>
  );
};

export default ApexChart;
