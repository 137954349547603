import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import AppReports from "../../components/AppReports";
import CallReports from "../../components/CallReports"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#F0F3FD"
  },
  tabContent: {
    padding: theme.spacing(3),
  },
}));

const Reports = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0); // Estado para controlar a aba ativa

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderContent = () => {
    switch (value) {
      case 0:
        return <AppReports />;
      case 1:
        return <CallReports/>; 
      default:
        return null;
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} indicatorColor="white">
          <Tab label="Relatórios do ConnectApp" />
          <Tab label="Relatórios de Ligação" />
        </Tabs>
      </AppBar>
      <Box className={classes.tabContent}>
        {renderContent()}
      </Box>
    </div>
  );
};

export default Reports;
