import React, { useEffect } from "react";
import "leaflet/dist/leaflet.css"; 
import L from "leaflet";
import { Button, Divider } from "@material-ui/core";
import mapPinIconUrl from '../../assets/map-pin.png'; // Ajuste o caminho conforme necessário



const LocationPreview = ({ latitude, longitude, description, messageId }) => {

  useEffect(() => {
    if (latitude && longitude && messageId) {
      const mapId = `map-${latitude}-${longitude}-${messageId}`;
      const map = L.map(mapId, {
        center: [latitude, longitude],
        zoom: 14,
        dragging: false, // Bloqueia a interação do usuário com o mapa
      });

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 19,
      }).addTo(map);

      const customIcon = L.icon({
        iconUrl: mapPinIconUrl,
        iconSize: [32, 32], // Tamanho do ícone
        iconAnchor: [16, 32], // Ponto de ancoragem do ícone (meio inferior)
        popupAnchor: [0, -32] // Ponto de ancoragem do popup (acima do ícone)
      });

      L.marker([latitude, longitude], { icon: customIcon })
      .addTo(map)
      .bindPopup(description || "Localização")
      .openPopup();

      map.on('click', () => {
        handleLocation();
      });

    }
  }, [latitude, longitude, description, messageId]);

  const handleLocation = () => {
    const url = `https://maps.google.com/maps?q=${latitude},${longitude}`;
    window.open(url);
  };

  if (!latitude || !longitude) {
    return null;
  }

  return (
    <div style={{ minWidth: "300px" }}>
      <div id={`map-${latitude}-${longitude}-${messageId}`} style={{ height: "250px", marginBottom: "10px" }} />
     {/*<Button fullWidth color="primary" onClick={handleLocation}>
        Visualizar no Google Maps
      </Button> */ }
    </div>
  );
};

export default LocationPreview;
