import React from "react";
import { Typography } from "@material-ui/core";
import { Handle } from "react-flow-renderer";

// Definindo o tipo de nó personalizado
const CustomNode = ({ data }) => {
  console.log("dados no nó", data);
  const handleHeight = 30; // Altura de cada handle
  const padding = 10; // Padding interno
  const baseHeight = 80; // Altura base para o título e paddings

  // Calcular a altura total do nó com base no número de outputs
  const nodeHeight =
    baseHeight + padding * 3 + handleHeight * data.queues.length;

  return (
    <div
      style={{
        padding: `${padding}px`,
        border: "1px solid #222",
        borderRadius: 5,
        position: "relative",
        width: 150,
        height: nodeHeight,
      }}
    >
      <Typography variant="body1" style={{ marginBottom: 10 }}>
        {data.label}
      </Typography>
      <Handle type="target" position="left" />
      {/* Handles de saída e nomes das filas */}
      {data.queues.map((output, index) => (
        <div
          key={index}
          style={{ position: "relative", marginBottom: handleHeight }}
        >
          <Handle
            type="source"
            position="right"
            id={`output-${index}`}
            style={{ top: `${index * handleHeight + 40}px` }}
          />
          <Typography
            variant="body2"
            style={{
              position: "absolute",
              left: "45px", // Posição horizontal
              top: `${padding + index * handleHeight + 25}px`,
              whiteSpace: "nowrap", // Impede a quebra de linha
              overflow: "hidden", // Oculta o excesso
              textOverflow: "ellipsis", // Adiciona os três pontos
              width: "50px", // Largura máxima do texto
              fontSize: "12px",
            }}
          >
            {output.label} {/* nome da fila */}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default CustomNode;
