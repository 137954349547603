import React, { useState, useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import Link from "@material-ui/core/Link";
import InputLabel from "@material-ui/core/InputLabel";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { green } from "@material-ui/core/colors";
import ModalImageDetails from "../ModalImageDetails";
import { parseISO, format, isSameDay } from "date-fns";

import { i18n } from "../../translate/i18n";

import ContactModal from "../ContactModal";
import ContactDrawerSkeleton from "../ContactDrawerSkeleton";
import MarkdownWrapper from "../MarkdownWrapper";
import api from "../../services/api";
import TicketInfo from "../TicketInfo";
import { Can } from "../Can";
import { AuthContext } from "../../context/Auth/AuthContext";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  ticket: {
    position: "relative",
  },

  pendingTicket: {
    cursor: "unset",
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  contactNameWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },

  lastMessageTime: {
    justifySelf: "flex-end",
    marginRight: 10,
    marginTop: 8,
  },

  closedBadge: {
    alignSelf: "center",
    justifySelf: "flex-end",
    marginRight: 32,
    marginLeft: "auto",
  },

  contactLastMessage: {
    paddingRight: 20,
  },

  newMessagesCount: {
    alignSelf: "center",
    marginRight: 22,
    marginLeft: "auto",
  },

  bottomButton: {
    top: "0px",
    padding: 5,
  },

  badgeStyle: {
    color: "white",
    backgroundColor: green[500],
  },

  acceptButton: {
    position: "absolute",
    left: "50%",
  },

  ticketQueueColor: {
    flex: "none",
    width: "8px",
    height: "100%",
    position: "absolute",
    top: "0%",
    left: "0%",
  },

  userTag: {
    position: "absolute",
    marginRight: 5,
    right: 20,
    marginTop: -3,
    // bottom: 30,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    border: "1px solid #CCC",
    padding: 1,
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 10,
    fontSize: "0.9em",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    display: "flex",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  header: {
    display: "flex",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: "#eee",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    minHeight: "73px",
    justifyContent: "flex-start",
  },
  content: {
    display: "flex",
    backgroundColor: "#eee",
    flexDirection: "column",
    padding: "8px 0px 8px 8px",
    height: "100%",
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },

  contactAvatar: {
    margin: 15,
    width: 160,
    height: 160,
  },

  contactHeader: {
    display: "flex",
    padding: 8,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& > *": {
      margin: 4,
    },
  },

  contactDetails: {
    marginTop: 8,
    padding: 8,
    display: "flex",
    flexDirection: "column",
  },
  contactExtraInfo: {
    marginTop: 4,
    padding: 6,
  },
}));

const ContactDrawer = ({
  open,
  handleDrawerClose,
  contact,
  loading,
  ticketId,
  isGroup,
}) => {
  const classes = useStyles();

  const [modalOpen, setModalOpen] = useState(false);
  const [dados, setData] = useState([]);
  const [isMedias, setIsMedias] = useState([]);
  const [isGroupAdmin, setIsGroupAdmin] = useState(null);
  const [setLoading, setLoadingStatus] = useState(false);
  const [dataCtgGroup, setDataCtgGroup] = useState([]);
  const [name, setName] = useState("");
  const { user: loggedInUser } = useContext(AuthContext);
  let valueParticpantGroup = [];
  let contactGroup = [];
  let userArr = [];

  let ticket = [];
  ticket.id = 1000;
  let medias = [];

  const filter = (e) => {
    const keyword = e.target.value;
    console.log("Item digitado: " + keyword);

    if (keyword !== "") {
      //for(var i =0;dados?.dados?.contacts.length < i;i++)
      const results = dados?.filter((user) => {
        console.log("usuario encontrado: " + user.name);
        if (user?.name?.toLowerCase().includes(keyword)) {
          userArr.push({
            name: user.name,
            number: user.number,
          });
          return userArr; //.toLowerCase().contains(keyword.toLowerCase());
        }
      });
      setDataCtgGroup(results);
    } else {
      setDataCtgGroup(dados);
    }
    setName(keyword);
  };

  const getInfo = async (id) => {
    let contactGroupItem = [];
    setLoadingStatus(true);
    console.log("Obtendo informa��es do Grupo... " + id);
    try {
      const { data } = await api.post("/group/show/" + id);

      /*for(var i=0;data.dados.grupoMeta.participants.length > i;i++){

				valueParticpantGroup = {
					number: data.dados.grupoMeta.participants[i].id.split('@')[0],
					}
				const contactGroupItem = api.post("/contact", valueParticpantGroup);
				console.log('Dados obtidos do contato: ', contactGroupItem)
				contactGroup[i] = {
					item: contactGroupItem,
				}

			}*/
      setData(JSON.parse(data.dados));
      setDataCtgGroup(JSON.parse(data.dados));
      setData(JSON.parse(data.dados));
      setDataCtgGroup(JSON.parse(data.dados));

      const contact = JSON.parse(data.dados).find((contact) => contact.isMe);
      setIsGroupAdmin(contact.admin);
    } catch (err) {
      setLoadingStatus(false);
    }
    setLoadingStatus(false);
  };

  const getMedias = async (id) => {
    setLoadingStatus(true);
    console.log("Obtendo informa��es das Medias com o contato: " + id);
    try {
      const { data } = await api.post("/medias/" + id);
      //	let dadosMedia=JSON.parse(data.dados)
      let dadosMedia = data.dados;
      console.log("Medias recebidas/enviadas para o contato2:", data.dados);
      medias = JSON.parse(data.dados);
      console.log("Objetos de media montado: ", medias);
      setIsMedias(medias);
      //console.log('Medias recebidas/enviadas para o contato2:',response)
    } catch (err) {
      //setIsMedias(false)
      setLoadingStatus(false);
    }
    setLoadingStatus(false);
  };

  const leaveGroup = async () => {
    try {
      const contact = dados.find((contact) => contact.isMe);
      console.log("meu contato", contact);
      if (contact) {
        const { data } = await api.post("/group/remove/" + contact?.number, {
          ticketId,
        });

        document.getElementById("getMembers").click();
      } else {
        alert("Superadmin n?o encontrado.");
      }
    } catch (err) {
      alert("N?o foi poss?vel sair do grupo. Erro:" + err.message);
    }
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={open}
      PaperProps={{ style: { position: "absolute" } }}
      BackdropProps={{ style: { position: "absolute" } }}
      ModalProps={{
        container: document.getElementById("drawer-container"),
        style: { position: "absolute" },
      }}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.header}>
        <IconButton onClick={handleDrawerClose}>
          <CloseIcon />
        </IconButton>
        <Typography style={{ justifySelf: "center" }}>
          {i18n.t("contactDrawer.header")}
        </Typography>
      </div>
      {setLoading ? (
        <ContactDrawerSkeleton classes={classes} />
      ) : (
        <div className={classes.content}>
          <Paper square variant="outlined" className={classes.contactHeader}>
            <Avatar
              alt={contact?.name}
              src={contact?.profilePicUrl}
              className={classes.contactAvatar}
            ></Avatar>

            <Typography>{contact?.name}</Typography>
            <Typography>
              <Link href={`tel:${contact?.number}`}>
                {contact?.number} | Grupo?: {contact?.isGroup}
              </Link>
            </Typography>

            <Button
              variant="outlined"
              color="primary"
              onClick={() => setModalOpen(true)}
            >
              {i18n.t("contactDrawer.buttons.edit")}
            </Button>
          </Paper>
          <Paper square variant="outlined" className={classes.contactDetails}>
            <ContactModal
              open={modalOpen}
              onClose={() => setModalOpen(false)}
              contactId={contact?.id}
              ticketId={ticketId}
            ></ContactModal>
            <Typography variant="subtitle1">
              {i18n.t("contactDrawer.extraInfo")}
            </Typography>
            {contact?.extraInfo?.map((info) => (
              <Paper
                key={info.id}
                square
                variant="outlined"
                className={classes.contactExtraInfo}
              >
                <InputLabel>{info.name}</InputLabel>
                <Typography component="div" noWrap style={{ paddingTop: 2 }}>
                  <MarkdownWrapper>{info.value}</MarkdownWrapper>
                </Typography>
              </Paper>
            ))}
            {contact?.isGroup && (
              <Button
                id="getMembers"
                variant="outlined"
                color="primary"
                onClick={() => getInfo(ticketId)}
              >
                Obter Participantes
              </Button>
            )}
            <Typography
              component="div"
              noWrap
              style={{ paddingTop: 2 }}
            ></Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => getMedias(ticketId)}
            >
              Obter Medias
            </Button>

            {/*): '' }*/}
          </Paper>
          <Paper>
            <div style={{ padding: 10, width: "100%", margin: "auto" }}>
              <input
                type="search"
                value={name}
                onChange={filter}
                style={{ padding: "5px,15px", width: "100%", height: 30 }}
                placeholder="Pesquisar contatos..."
              />
            </div>
          </Paper>
          {isMedias?.map((dataMedia) => (
            <ModalImageDetails
              imageName={dataMedia.body}
              imageDate={dataMedia.createdAt}
              imageUrl={dataMedia.mediaUrl}
            />
          ))}
          {dataCtgGroup?.map((dados) => (
            <TicketInfo
              contact={dados}
              ticket={ticketId}
              isAvatar={true}
              isGroupAdmin={isGroupAdmin}
              //onClick={handleDrawerOpen}
            />
          ))}
          {/*{isMedias &&
						<ReactMediaView
							media={[medias]}
							columnCount={2}
							emptyState={"Clique no bot�o obter medias para exibir os arquivos trocados com esse contato"}
						/>
					}*/}
          {dataCtgGroup.length > 0 && (
            <Paper square variant="outlined" className={classes.contactDetails}>
              <div style={{ padding: 10, width: "100%" }}>
                <Button
                  variant="outlined"
                  style={{
                    width: "100%",
                    display: "flex",
                    columnGap: "10px",
                    color: "#EA0038",
                  }}
                  onClick={() => leaveGroup()}
                >
                  <ExitToAppIcon />
                  Sair do Grupo
                </Button>
              </div>
            </Paper>
          )}
        </div>
      )}
    </Drawer>
  );
};

export default ContactDrawer;
