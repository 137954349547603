import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CustomTable from "../../components/TableWithPagination";
import api from "../../services/api";
import {
  Button,
  Drawer,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@material-ui/core";
import DateAndTimePicker from "../../components/DateAndTimePicker";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    padding: theme.spacing(2),
  },
  drawer: {
    width: 300,
    padding: theme.spacing(2),
  },
}));

const CallReports = () => {
  const classes = useStyles();
  const [calls, setCalls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [count, setCount] = useState(0);
  const [filterOpen, setFilterOpen] = useState(false);
  const [fromFilter, setFromFilter] = useState("");
  const [toFilter, setToFilter] = useState("");
  const [directionFilter, setDirectionFilter] = useState("all"); // "outbound", "inbound" ou "all"
  const [resultFilter, setResultFilter] = useState("all"); // "answered", "not_answered" ou "all"
  const [filteredCalls, setFilteredCalls] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const fetchCalls = async () => {
      try {
        const response = await api.get("/calls");
        const formattedCalls = response.data.map((call) =>
          formatCallData(call)
        );
        setCalls(formattedCalls);
        setFilteredCalls(formattedCalls);
        setCount(formattedCalls.length);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCalls();
  }, []);

  const formatCallData = (call) => {
    const startTime = new Date(call.startTime);
    const endTime = call.endTime ? new Date(call.endTime) : null;

    let durationFormatted = "N/A";
    if (endTime) {
      const durationInMilliseconds = endTime - startTime;
      const durationInSeconds = Math.floor(durationInMilliseconds / 1000);
      durationFormatted =
        durationInSeconds < 60
          ? `${durationInSeconds} s`
          : `${Math.floor(durationInSeconds / 60)} min`;
    }

    return {
      ...call,
      from: call.from.replace(/\D/g, ""),
      to: call.to.replace(/\D/g, ""),
      direction: call.direction === "outbound" ? "Saída" : "Entrada",
      result: call.result === "answered" ? "Atendida" : "Não Atendida",
      createdAt: new Date(call.createdAt).toLocaleString(),
      duration: durationFormatted,
      user: call.user.name,
    };
  };

  const handleFilterOpen = () => {
    setFilterOpen(true);
  };

  const handleFilterClose = () => {
    setFilterOpen(false);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const applyFilters = () => {
    let newFilteredCalls = [...calls];

    // Filtragem por "De"
    if (fromFilter) {
      newFilteredCalls = newFilteredCalls.filter((call) =>
        call.from.includes(fromFilter)
      );
    }

    // Filtragem por "Para"
    if (toFilter) {
      newFilteredCalls = newFilteredCalls.filter((call) =>
        call.to.includes(toFilter)
      );
    }

    // Filtragem por direção
    if (directionFilter !== "all") {
      newFilteredCalls = newFilteredCalls.filter(
        (call) =>
          call.direction ===
          (directionFilter === "outbound" ? "Saída" : "Entrada")
      );
    }

    // Filtragem por resultado
    if (resultFilter !== "all") {
      newFilteredCalls = newFilteredCalls.filter(
        (call) =>
          call.result ===
          (resultFilter === "answered" ? "Atendida" : "Não Atendida")
      );
    }

    // Filtragem por data de início
    console.log("data inicial", startDate);
    console.log("data final", endDate);
    if (startDate) {
      newFilteredCalls = newFilteredCalls.filter((call) => {
        const callDate = moment(
          call.createdAt,
          "DD/MM/YYYY, HH:mm:ss"
        ).toDate();
        return callDate >= startDate;
      });
    }

    // Filtragem por data final
    if (endDate) {
      newFilteredCalls = newFilteredCalls.filter((call) => {
        const callDate = moment(
          call.createdAt,
          "DD/MM/YYYY, HH:mm:ss"
        ).toDate();
        return callDate <= endDate;
      });
    }

    console.log("Filtered Calls Count:", newFilteredCalls.length);

    setFilteredCalls(newFilteredCalls);
    handleFilterClose();
  };

  const columns = [
    { field: "user", headerName: "Usuário", minWidth: 50, sortable: true },
    { field: "from", headerName: "De", minWidth: 100 },
    { field: "to", headerName: "Para", minWidth: 100 },
    { field: "direction", headerName: "Tipo", minWidth: 100 },
    { field: "result", headerName: "Resultado", minWidth: 100 },
    { field: "createdAt", headerName: "Data", minWidth: 200 },
    { field: "duration", headerName: "Duração", minWidth: 100 },
  ];

  return (
    <div className={classes.root}>
      <div
        style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
      >
        <Button variant="outlined" onClick={handleFilterOpen}>
          Filtro
        </Button>
      </div>
      <Drawer anchor="right" open={filterOpen} onClose={handleFilterClose}>
        <div className={classes.drawer}>
          <Typography variant="h6">Filtros</Typography>
          <div style={{ display: "flex", flexDirection: "column", rowGap: "10px" }}>
            <DateAndTimePicker
              label="Data Inicial"
              value={startDate}
              onChange={setStartDate}
            />
            <DateAndTimePicker
              label="Data Final"
              value={endDate}
              onChange={setEndDate}
            />
          </div>
          <TextField
            label="De"
            variant="outlined"
            fullWidth
            size="small"
            value={fromFilter}
            onChange={(e) => setFromFilter(e.target.value)}
            margin="normal"
          />
          <TextField
            label="Para"
            variant="outlined"
            fullWidth
            size="small"
            value={toFilter}
            onChange={(e) => setToFilter(e.target.value)}
            margin="normal"
          />
          <FormControl component="fieldset">
            <FormLabel component="legend">Tipo</FormLabel>
            <RadioGroup
              value={directionFilter}
              onChange={(e) => setDirectionFilter(e.target.value)}
              row
            >
              <FormControlLabel value="all" control={<Radio />} label="Todos" />
              <FormControlLabel
                value="outbound"
                control={<Radio />}
                label="Saída"
              />
              <FormControlLabel
                value="inbound"
                control={<Radio />}
                label="Entrada"
              />
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset">
            <FormLabel component="legend">Resultado</FormLabel>
            <RadioGroup
              value={resultFilter}
              onChange={(e) => setResultFilter(e.target.value)}
              row
            >
              <FormControlLabel value="all" control={<Radio />} label="Todos" />
              <FormControlLabel
                value="answered"
                control={<Radio />}
                label="Atendida"
              />
              <FormControlLabel
                value="not_answered"
                control={<Radio />}
                label="Não Atendida"
              />
            </RadioGroup>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            onClick={applyFilters}
            fullWidth
          >
            Aplicar Filtros
          </Button>
        </div>
      </Drawer>
      {loading ? (
        <Typography variant="h6">Carregando...</Typography>
      ) : (
        <>
          <div style={{ marginTop: "1rem" }}>
            <CustomTable
              columns={columns}
              data={filteredCalls}
              rowsPerPageOptions={[5, 10, 25]}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              count={count}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CallReports;
