import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import SendIcon from "@material-ui/icons/Send";
import IconButton from "@material-ui/core/IconButton";
import api from "../../services/api";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    background: "#eee",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    position: "fixed",
    bottom: 15,
    zIndex: 100,
    width: "auto", // Ajustado para 'auto' para se ajustar dinamicamente
  },
  newMessageBox: {
    background: "#eee",
    width: "100%",
    display: "flex",
    padding: "18px",
    alignItems: "center",
    position: "relative",
  },
  messageInputWrapper: {
    padding: 6,
    marginRight: 7,
    background: "#fff",
    display: "flex",
    borderRadius: 20,
    flex: 1,
    position: "relative",
  },
  messageInput: {
    paddingLeft: 10,
    flex: 1,
    border: "none",
  },
  sendMessageIcons: {
    color: "grey",
  },
}));

const EditMessageChat = ({ onSendMessage, messageId, onClose, parentSize, messageBody }) => {
  const classes = useStyles();
  const [inputMessage, setInputMessage] = useState(messageBody);
  const inputRef = useRef(null);

  const handleChangeInput = (e) => {
    setInputMessage(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && inputMessage.trim() !== "") {
      sendMessage();
    }
  };

  const sendMessage = async () => {
    if (inputMessage.trim() !== "") {
      try {
        await api.post(`/messages/edit/${messageId}`, { text: inputMessage });
        onSendMessage(inputMessage);
        setInputMessage("");
      } catch (error) {
        console.error("Erro ao editar mensagem:", error);
      }
    }
  };

  useEffect(() => {
    inputRef.current && inputRef.current.focus();

    const handleClickOutside = (e) => {
      if (
        !e.target.closest(`.${classes.newMessageBox}`) &&
        inputRef.current &&
        !inputRef.current.contains(e.target)
      ) {
        onClose(); // Emite evento para fechar o componente
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={classes.mainWrapper}
      style={{ width: parentSize ? parentSize.width + "px" : "auto" }} // Ajusta a largura dinamicamente com base no tamanho do pai
    >
      <div className={classes.newMessageBox}>
        <div className={classes.messageInputWrapper}>
          <InputBase
            inputRef={inputRef}
            className={classes.messageInput}
            placeholder="Digite sua nova mensagem"
            multiline
            maxRows={5}
            value={inputMessage}
            onChange={handleChangeInput}
            onKeyPress={handleKeyPress}
          />
        </div>
        <IconButton
          aria-label="sendMessage"
          component="span"
          onClick={sendMessage}
        >
          <SendIcon className={classes.sendMessageIcons} />
        </IconButton>
      </div>
    </div>
  );
};

export default EditMessageChat;
