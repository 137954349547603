import React, { useState, useRef, useEffect, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  CssBaseline,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  Link
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { versionSystem } from "../../../package.json";
import { nomeEmpresa } from "../../../package.json";
import logo from '../../assets/logo.png';
import bg from '../../assets/login-bg3.png';
import logoApp from '../../assets/Logo-App.png'
import animatedChat from '../../assets/Whatsapp.mp4' 

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      © {new Date().getFullYear()}
      {" - "}
      <Link color="inherit" href="#">
        { nomeEmpresa } - v { versionSystem }
      </Link>
      {"."}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "40px"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    display: "flex",
    height: "100vh",
    overflow: "hidden",
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column",
    },
  },
  imageContainer: {
    flex: 1.5,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    '@media (max-width: 790px)': {
      display: 'none',
    },
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },

  video: {
    position: "absolute",
    top: "20%", 
    left: "38%",
    width: "120px",
    height: "auto",
    zIndex: 1,
  },
  
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "80px 80px 0 80px",
  },
  contentContainer: {
    width: "80%",
  }
}));

const Login = () => {
  const classes = useStyles();

  const [user, setUser] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);

  const { handleLogin } = useContext(AuthContext);

  const videoRef = useRef(null);
  const pauseDuration = 2000;

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleEnded = () => {
      setTimeout(() => {
        videoElement.play();
      }, pauseDuration);
    };

    if (videoElement) {
      videoElement.addEventListener('ended', handleEnded);
    }

    // Limpeza do evento
    return () => {
      if (videoElement) {
        videoElement.removeEventListener('ended', handleEnded);
      }
    };
  }, [pauseDuration]);

  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handlSubmit = (e) => {
    e.preventDefault();
    handleLogin(user);
  };

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <img alt="background" src={bg} className={classes.image} />
        <div style={{display: "flex", justifyContent: "center"}}>
          <video
            ref={videoRef} // Adicione esta linha
            className={classes.video}
            autoPlay
            muted
          >
            <source src={animatedChat} type="video/mp4" />
            Seu navegador não suporta o elemento de vídeo.
          </video>
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.contentContainer}>
          <div style={{display: "flex", alignItems: "center", justifyContent: "center", columnGap: "10px"}}>
            <img alt="logo" src={logoApp} style={{width: "80px"}} />
          </div>
          <div className={classes.paper}>
            <form className={classes.form} noValidate onSubmit={handlSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label={i18n.t("login.form.email")}
                name="email"
                value={user.email}
                onChange={handleChangeInput}
                autoComplete="email"
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label={i18n.t("login.form.password")}
                id="password"
                value={user.password}
                onChange={handleChangeInput}
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((e) => !e)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {i18n.t("login.buttons.submit")}
              </Button>
            </form>
          </div>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </div>
    </div>
  );
};

export default Login;
