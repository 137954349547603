import React, { useEffect, useReducer, useState } from "react";
import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import { DeleteOutline, Edit } from "@material-ui/icons";
import FlowModal from "../../components/FlowModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import openSocket from "../../services/socket-io"; 
import api from "../../services/api";
import { toast } from "react-toastify";
import ReactFlowBuilder  from "../../components/ReactFlowBuilder";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
  },
}));

const initialFlows = [];

const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_FLOWS":
      return action.payload;
    case "UPDATE_FLOW":
      return state.map((flow) =>
        flow.id === action.payload.id ? action.payload : flow
      );
    case "DELETE_FLOW":
      return state.filter((flow) => flow.id !== action.payload);
    case "ADD_FLOW":
      return [...state, action.payload];
    default:
      return state;
  }
};

const Flows = () => {
  const classes = useStyles();
  const [flows, dispatch] = useReducer(reducer, initialFlows);
  const [flowModalOpen, setFlowModalOpen] = useState(false);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [flowBuilderOpen, setFlowBuilderOpen] = useState(false);

  const fetchFlows = async () => {
    try {
      const { data } = await api.get("/flows");
      dispatch({ type: "LOAD_FLOWS", payload: data });
    } catch (error) {
      console.error("Erro ao carregar fluxos:", error);
    }
  };

  useEffect(() => {
    fetchFlows();
  }, []);

  // Para notificar outros usuarios que estao na mesma page em tempo real com os dados atualizados
  useEffect(() => {
    const socket = openSocket();

    socket.on("flow", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_FLOW", payload: data.flow });
      } else if (data.action === "delete") {
        dispatch({ type: "DELETE_FLOW", payload: data.flowId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenFlowModal = () => {
    setFlowModalOpen(true);
    setSelectedFlow(null);
  };

  const handleCloseFlowModal = () => {
    setFlowModalOpen(false);
    setSelectedFlow(null);
  };

  const handleEditFlow = (flow) => {
    setSelectedFlow(flow);
    setFlowModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedFlow(null);
  };

  const handleDeleteFlow = async (flowId) => {
    try {
      await api.delete(`/flows/${flowId}`); 
      dispatch({ type: "DELETE_FLOW", payload: flowId }); 
      toast.success("Fluxo deletado com sucesso!");
    } catch (error) {
      toast.error("Erro ao deletar o fluxo.");
    } finally {
      setConfirmModalOpen(false); 
    }
  };
  

  // Para o user local ver as mudancas feitas
  const handleSaveFlow = (flow) => {
    if (selectedFlow) {
      dispatch({ type: "UPDATE_FLOW", payload: flow }); 
    } else {
      dispatch({ type: "ADD_FLOW", payload: flow }); 
    }
    handleCloseFlowModal();
  };
  

  const handleOpenFlowBuilder = (flow) => {
    setSelectedFlow(flow);
    setFlowBuilderOpen(true);
  };

  const handleCloseFlowBuilder = () => {
    setFlowBuilderOpen(false);
    setSelectedFlow(null);
  }
  

  return (
    <MainContainer>
      <ConfirmationModal
        title={selectedFlow && `Deseja deletar ${selectedFlow.name}?`}
        open={confirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => handleDeleteFlow(selectedFlow.id)}
      >
        Tem certeza que deseja deletar este fluxo?
      </ConfirmationModal>
      <FlowModal
        open={flowModalOpen}
        onClose={handleCloseFlowModal}
        flowId={selectedFlow?.id}
        onSave={handleSaveFlow}
      />
     {flowBuilderOpen && (
        <ReactFlowBuilder 
          open={flowBuilderOpen} 
          onClose={() => setFlowBuilderOpen(false)} 
          flowId={selectedFlow?.id} 
        />
      )}
      <MainHeader>
        <Title>Fluxos</Title>
        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenFlowModal}
          >
            Adicionar Fluxo
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">Nome</TableCell>
              <TableCell align="center">Descrição</TableCell>
              <TableCell align="center">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {flows.map((flow) => (
              <TableRow key={flow.id} onClick={() => handleOpenFlowBuilder(flow)}>
                <TableCell align="center">{flow.name}</TableCell>
                <TableCell align="center">{flow.description}</TableCell>
                <TableCell align="center">
                <IconButton size="small" onClick={(e) => { e.stopPropagation(); handleEditFlow(flow); }}>
                    <Edit />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={(e) => { e.stopPropagation(); setSelectedFlow(flow); setConfirmModalOpen(true); }}>
                    <DeleteOutline />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Flows;
